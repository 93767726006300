import {
  Button,
  Checkbox,
  Image,
  Input,
  Pagination,
  Select,
  Space,
  Table,
  TableColumnsType,
  Tabs,
  TabsProps,
  Tag,
  Timeline,
  Typography,
} from 'antd';

import { useEffect, useState } from 'react';
import { useQuery } from '../hooks/useQuery';
import { useNavigate, useParams } from 'react-router-dom';

import { client } from '../axios';

import { continent } from '../db/country';
import { IMAGE_URL } from '../const/imageUrl';
import { fallbackImg } from '../db/fallbackImg';
import TextArea from 'antd/es/input/TextArea';
import moment from 'moment';

const { Title } = Typography;

const options = [
  {
    value: 'email',
    label: '이메일',
  },
  {
    value: 'name',
    label: '이름',
  },
  {
    value: 'nickname',
    label: '닉네임',
  },
  {
    value: 'phone',
    label: '연락처',
  },
];

export function Service() {
  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const [service, setService] = useState<any>();
  const serviceId = params.id;

  const getService = () => {
    client.get(`/service/admin/${serviceId}`).then((res) => {
      setService(res.data);
    });
  };

  useEffect(() => {
    getService();
  }, []);

  const items: TabsProps['items'] = [
    {
      key: '0',
      label: '상품 히스토리',
      children: (
        <div>
          <Title style={{ marginBottom: 20 }} level={4}></Title>
          <Timeline
            items={
              //   [
              //   {
              //     children: 'Create a services site 2015-09-01',
              //   },
              //   {
              //     children: 'Solve initial network problems 2015-09-01',
              //   },
              //   {
              //     children: 'Technical testing 2015-09-01',
              //   },
              //   {
              //     children: 'Network problems being solved 2015-09-01',
              //   },
              // ]
              // 0: 가입완료, 1: 기본정보입력완료, 2: 전문가증빙제출완료
              service?.serviceHistories?.map((el: any) => {
                return {
                  color:
                    el?.status == 3
                      ? 'red'
                      : el?.status == 0
                      ? 'green'
                      : 'blue',
                  children: `${
                    el?.status == 0
                      ? '승인'
                      : el?.status == 1
                      ? '심사중'
                      : el?.status == 2
                      ? '판매중'
                      : el?.status == 3
                      ? '반려'
                      : el?.status == 4
                      ? '재심사'
                      : el?.status == 5
                      ? '휴면'
                      : el?.status == 6
                      ? '휴면해제요청'
                      : el?.status == 7
                      ? '삭제'
                      : el?.status == 8
                      ? '숨김'
                      : '-'
                  } |  ${moment(el?.createdTime).format('YYYY-MM-DD HH:mm')}`,
                };
              })
            }
          />
        </div>
      ),
    },
    {
      key: '1',
      label: '대륙/국가/도시',
      children: (
        <div>
          <div>
            <div style={{ marginBottom: 5 }}>대륙</div>
            <Input
              size='large'
              disabled
              value={
                continent?.find((el) => el.value == service?.continent)?.name ||
                '-'
              }
              style={{ backgroundColor: '#fff', color: '#000' }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <div style={{ marginBottom: 5 }}>국가</div>
            <Input
              size='large'
              disabled
              value={service?.country || '-'}
              style={{ backgroundColor: '#fff', color: '#000' }}
            />
          </div>
          <div style={{ marginTop: 10 }}>
            <div style={{ marginBottom: 5 }}>도시</div>
            <Input
              size='large'
              disabled
              value={service?.city || '-'}
              style={{ backgroundColor: '#fff', color: '#000' }}
            />
          </div>

          <div style={{ marginTop: 20 }}>
            <Checkbox checked={service?.isNoCountry == 1 ? true : false}>
              해당하는 대륙,국가,도시가 없습니다
            </Checkbox>
          </div>
        </div>
      ),
    },
    {
      key: '2',
      label: '제목',
      children: (
        <div>
          <div style={{ marginBottom: 5 }}>제목</div>
          <Input
            size='large'
            disabled
            value={service?.name || '-'}
            style={{ backgroundColor: '#fff', color: '#000' }}
          />
        </div>
      ),
    },
    {
      key: '3',
      label: '가격',
      children: (
        <div>
          <div>
            <div style={{ fontWeight: 600, marginBottom: 5 }}>1일당 가격 </div>
            <Input
              size='large'
              disabled
              value={service?.planningPriceOriginal ?? '-'}
              style={{ backgroundColor: '#fff', color: '#000' }}
            />
          </div>

          <div style={{ fontWeight: 600, marginTop: 20 }}>작업 소요 기간</div>
          {service?.deadLine.map((el: any, i: number) => (
            <>
              <div key={i} style={{ margin: '20px 0' }}>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ marginBottom: 0 }}>여행 일정</div>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      size='large'
                      type='number'
                      placeholder='여행일정을 입력해주세요.'
                      style={{ marginTop: 10 }}
                      value={el?.startDate ?? '-'}
                    />
                    <div style={{ marginLeft: 5, marginRight: 20 }}>박</div>
                    <Input
                      size='large'
                      type='number'
                      placeholder='여행일정을 입력해주세요.'
                      style={{ marginTop: 10 }}
                      value={el?.endDate ?? '-'}
                    />
                    <div style={{ marginLeft: 5 }}>일</div>
                  </div>
                </div>
                <div style={{ marginTop: 10 }}>
                  <div style={{ marginBottom: 0 }}>작업일</div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Input
                      size='large'
                      type='number'
                      placeholder='작업일을 입력해주세요.'
                      style={{ marginTop: 10 }}
                      value={el?.workDay ?? '-'}
                    />
                    <div style={{ marginLeft: 5 }}>일</div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  margin: '24px 0',
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#dee2e6',
                }}
              />
            </>
          ))}

          <div
            style={{
              width: '100%',
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ fontWeight: 600, marginRight: 10 }}>
              항공권 탐색 제공
            </div>
            <Checkbox checked={service?.flightBookPrice ? true : false} />
          </div>
          {service?.flightBookPrice && (
            <>
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 5 }}>금액</div>
                <Input
                  size='large'
                  type='number'
                  placeholder='금액을 입력해주세요.'
                  value={service?.flightBookPrice || 0}
                />
              </div>
            </>
          )}

          <div
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ fontWeight: 600, marginRight: 10 }}>
              실시간 통역 제공
            </div>
            <Checkbox checked={service?.translatePrice ? true : false} />
          </div>
          {service?.translatePrice && (
            <>
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 5 }}>금액</div>
                <Input
                  size='large'
                  type='number'
                  placeholder='금액을 입력해주세요.'
                  value={service?.translatePrice || 0}
                />
              </div>
            </>
          )}

          <div
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ fontWeight: 600, marginRight: 10 }}>빠른 작업</div>
            <Checkbox checked={service?.quickWorkPrice ? true : false} />
          </div>
          {service?.quickWorkPrice && (
            <>
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 5 }}>금액</div>
                <Input
                  size='large'
                  type='number'
                  placeholder='금액을 입력해주세요.'
                  value={service?.quickWorkPrice || 0}
                />
              </div>
            </>
          )}

          <div
            style={{
              marginTop: 20,
              width: '100%',
              display: 'flex',
              // justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ fontWeight: 600, marginRight: 10 }}>추가 수정</div>
            <Checkbox checked={service?.addModifyPrice ? true : false} />
          </div>
          {service?.addModifyPrice && (
            <>
              <div style={{ marginTop: 20 }}>
                <div style={{ marginBottom: 5 }}>1회당 금액</div>
                <Input
                  size='large'
                  type='number'
                  placeholder='금액을 입력해주세요.'
                  value={service?.addModifyPrice || 0}
                />
              </div>
            </>
          )}

          <div
            style={{
              margin: '24px 0',
              width: '100%',
              height: '1px',
              backgroundColor: '#dee2e6',
            }}
          />

          {service?.tblServiceOptions?.map((el: any, i: number) => {
            return (
              <>
                <div key={i}>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ fontWeight: 600 }}>맞춤 옵션</div>
                  </div>

                  <div style={{ marginTop: 20 }}>
                    <div style={{ marginBottom: 5 }}>제목</div>
                    <Input
                      size='large'
                      placeholder='제목을 입력해주세요.'
                      value={el?.name || '-'}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <div style={{ marginBottom: 5 }}>설명</div>
                    <Input
                      size='large'
                      placeholder='설명을 입력해주세요.'
                      value={el?.description || '-'}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <div style={{ marginBottom: 5 }}>추가금액</div>
                    <Input
                      type='number'
                      size='large'
                      placeholder='추가금액을 입력해주세요.'
                      value={el?.price ?? '-'}
                    />
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <div style={{ marginBottom: 5 }}>추가 작업일</div>
                    <Input
                      type='number'
                      size='large'
                      placeholder='추가작업일을 입력해주세요.'
                      value={el?.workDay ?? '-'}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '24px 0',
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#dee2e6',
                  }}
                />
              </>
            );
          })}
        </div>
      ),
    },
    {
      key: '4',
      label: '소개',
      children: (
        <div>
          {service?.summary?.map((el: any, i: number) => {
            return (
              <div key={i} style={{ margin: '20px 0' }}>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div style={{ marginBottom: 0 }}>제목</div>
                  </div>

                  <Input
                    size='large'
                    placeholder={el.placeholderTitle}
                    style={{ marginTop: 10 }}
                    showCount
                    value={el?.title || '-'}
                    maxLength={40}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <div style={{ marginBottom: 0 }}>세부설명</div>

                  <TextArea
                    size='large'
                    placeholder={el.placeholderContent}
                    style={{ marginTop: 10 }}
                    showCount
                    value={el?.content || '-'}
                    maxLength={40}
                    rows={3}
                  />
                </div>
                <div style={{ marginTop: 10 }}>
                  <div style={{ marginBottom: 0 }}>이미지</div>

                  <Image
                    // @ts-ignore
                    src={`${IMAGE_URL}${el?.filename}`}
                    fallback={fallbackImg}
                    style={{ width: 250, height: 250 }}
                  />
                </div>
              </div>
            );
          })}
          <div
            style={{
              margin: '24px 0',
              width: '100%',
              height: '1px',
              backgroundColor: '#dee2e6',
            }}
          />
          <div style={{ fontWeight: 600, marginBottom: 10, fontSize: 18 }}>
            제공하는 상품을 소개해 주세요!
          </div>
          <div dangerouslySetInnerHTML={{ __html: service?.detailInfo }}></div>
        </div>
      ),
    },
    {
      key: '5',
      label: '추천',
      children: (
        <div>
          {service?.recommendInfo?.split('|')?.map((el: any) => {
            return (
              <div style={{ margin: '10px 0' }}>
                <Input
                  size='large'
                  disabled
                  value={el}
                  style={{ backgroundColor: '#fff', color: '#000' }}
                />
              </div>
            );
          })}
        </div>
      ),
    },
    {
      key: '6',
      label: 'FAQ',
      children: (
        <div>
          {service?.faq?.map((el: any, i: number) => {
            return (
              <>
                <div
                  key={i}
                  style={{
                    margin: '20px 0',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <div style={{ marginBottom: 5 }}>제목</div>
                    </div>

                    <Input size='large' value={el?.question || '-'} />
                  </div>
                  <div style={{ marginTop: 10 }}>
                    <div style={{ marginBottom: 5 }}>세부설명</div>
                    <TextArea
                      rows={4}
                      maxLength={500}
                      showCount
                      value={el?.answer || '-'}
                    />
                  </div>
                </div>
                <div
                  style={{
                    margin: '24px 0',
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#dee2e6',
                  }}
                />
              </>
            );
          })}
        </div>
      ),
    },
    {
      key: '7',
      label: '유의사항',
      children: (
        <div>
          <div style={{ marginBottom: 0, fontWeight: '600', fontSize: 18 }}>
            수정 범위 및 규정
          </div>

          <TextArea
            size='large'
            rows={7}
            style={{ marginTop: 10 }}
            // showCount
            // maxLength={50}
            value={service?.noticeInfo || '-'}
          />
        </div>
      ),
    },
    {
      key: '8',
      label: '여행 경험 및 요청사항',
      children: (
        <div>
          <div style={{ margin: '20px  0px' }}>
            <div style={{ fontWeight: 600, fontSize: 18 }}>대표이미지</div>
            <Image
              // @ts-ignore
              src={`${IMAGE_URL}${service?.travelExperienceFilename}`}
              fallback={fallbackImg}
              style={{ width: 300 }}
            />
          </div>

          <div>
            <div style={{ fontWeight: 600, fontSize: 18 }}>여행 경험</div>

            <div>
              {service?.travelExperience
                ?.split('|')
                ?.map((el: any, i: number) => {
                  return (
                    <div
                      key={i}
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <Input
                        size='large'
                        placeholder='여행경험을 입력해주세요.'
                        style={{
                          marginTop: 10,
                          width: i !== 0 ? '95%' : '100%',
                        }}
                        value={el}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      ),
    },
    {
      key: '9',
      label: '요청사항',
      children: (
        <div>
          <div style={{ marginBottom: 20, fontWeight: 600, fontSize: 18 }}>
            상담 전 요청사항
          </div>

          {service?.request?.map((el: any, i: number) => {
            return (
              <div key={i} style={{ margin: '30px 0' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div style={{ marginBottom: 0 }}>질문</div>
                </div>

                <Input
                  size='large'
                  placeholder='Q.의뢰인에게 요청할 질문을 입력해주세요.'
                  style={{
                    marginTop: 10,
                    // width: i !== 0 ? '95%' : '100%',
                  }}
                  value={el?.question || '-'}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: 10,
                  }}
                >
                  <Checkbox
                    style={{ marginRight: 10 }}
                    checked={el.isRequired == 1}
                  />
                  <div style={{ marginBottom: 0 }}>필수 입력 항목</div>
                </div>
              </div>
            );
          })}
        </div>
      ),
    },
    {
      key: '10',
      label: '썸네일',
      children: (
        <div style={{ margin: '20px  0px' }}>
          <div style={{ fontWeight: 600, fontSize: 18 }}>썸네일</div>

          <Image
            // @ts-ignore
            src={`${IMAGE_URL}${service?.thumbnailFilename}`}
            fallback={fallbackImg}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      key: '11',
      label: '결과물',
      children: (
        <div>
          {service?.result?.map((el: any, i: number) => {
            return (
              <div key={i}>
                <div style={{ margin: '30px 0' }}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>결과물 사진</div>
                  </div>

                  <Image
                    style={{ width: 150, height: 150 }}
                    src={`${IMAGE_URL}${el.filename}`}
                    fallback={fallbackImg}
                  />
                  <div style={{ marginTop: 10 }}>결과물 설명</div>
                  <Input
                    placeholder='해당 결과물의 설명을 입력해주세요.'
                    size='large'
                    value={el?.content || '-'}
                  />
                </div>
                <div
                  style={{
                    margin: '24px 0',
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#dee2e6',
                  }}
                />
              </div>
            );
          })}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Title level={3}>상품 상세</Title>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <Button
          type='primary'
          onClick={() => {
            if (!window.confirm('해당 상품의 휴면 상태를 해제하시겠습니까?'))
              return;
            client
              .post(`/service/approve/undormancy/${service.uid}`)
              .then((res) => {
                alert('승인되었습니다.');
                getService();
              })
              .catch(() => {
                alert('상품 휴면해제에 실패했습니다.');
              });
          }}
          style={{ marginRight: 10 }}
        >
          휴면해제
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (!window.confirm('해당 상품의 휴면 해제를 반려하시겠습니까?'))
              return;
            const reason = prompt('반려 이유를 입력해주세요.');

            client
              .post(`/service/deny/undormancy/${service.uid}`, { reason })
              .then((res) => {
                alert('반려되었습니다.');
                getService();
              })
              .catch(() => {
                alert('상품 휴면 해제 반려에 실패했습니다.');
              });
          }}
          danger
          style={{ marginRight: 10 }}
        >
          휴면반려
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (!window.confirm('해당 상품을 승인하시겠습니까?')) return;
            client
              .post(`/service/approve/${service.uid}`)
              .then((res) => {
                alert('승인되었습니다.');
                getService();
              })
              .catch(() => {
                alert('상품 승인에 실패했습니다.');
              });
          }}
          style={{ marginRight: 10 }}
        >
          승인
        </Button>
        <Button
          type='primary'
          onClick={() => {
            if (!window.confirm('해당 상품을 반려하시겠습니까?')) return;
            const reason = prompt('반려 이유를 입력해주세요.');

            client
              .post(`/service/deny/${service.uid}`, { reason })
              .then((res) => {
                alert('반려되었습니다.');
                getService();
              })
              .catch(() => {
                alert('상품 반려에 실패했습니다.');
              });
          }}
          danger
        >
          반려
        </Button>
      </div>
      <Tabs defaultActiveKey='1' items={items} />
    </div>
  );
}
