import "src/styles/chat.style.css.ts.vanilla.css!=!../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/chat.style.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAE7VVTW+jMBC991dYWq3UHhwZkpCGXPayP8TBQ+KWYGRMoV3lv+9g8+EQUFfbXeUSjcczb957HlbVi672H4z8eiDkDPJ0NjFJeJY8Boy9nQklz6xong54elRagKaaC1mVMdkWTRu9cH2SeUwY4ZVRbSTNoKFCakiMVHiSqKy65O2JkGWR8ffYphwerg8r1z2w3SenhLxUpZHpO01UbiBHXGXBE6BHMDWALVhwIWR+iknAHBqeyVNOpYELIkzwEmgP+lEZoy4xCYuGlCqTgnz7uW1/Nocnryetqlxgw0zpmHxL09RDGVqUS2l/hf8LcHutNs9u8oEKZIIEVpwB+doiTxEGLeUHIF2Ru2RDdVcpYsy7s7F37gguVCmdrKlsQBzm62aQYkHW/tWuOHNzuYHYfO9hcKOKJZH+RMhZn6o30Gmm6picpRAw58cZxUZNlqT6zDXbT1xjXwseIbDAoW9aMi3pw7S3Ck/UjZy6kzoanCS5ysHOX5lM5tArMYOHpevUI3C8OiE0CG8cR53YvUF6X66ZZ7HOHevOHYiD3uYNw+zmV8F0p+CcVANKWsKdODYXcjFup8EvwQyozU3/Z9u/PmMtap9rTAoNtNa88F1kAyiQBv5Ka+RndhvNsBwe9xEk7eHEB0vbtaG1FOaMEfb9jrxgFXrY9/Pc/Tty+H8mB4L255PDbvbCV8g5WuwGGkMtIf4jnvFox43bROwmNiyfLtxj3Yf4W7ctf1xASE7KROOeJzwX5PGCFzusuwhX9pOFs/K/vsisS8Bl6CqPr2m3HUL965zdfXcg+2d4HbuFXbehdniXsu5SPGLC+6zNcpb/ocCVmry+9+C9L8B1gapR1l20W6BqEJk55f2G/Ii8VAYOPqljotWUTWbZdnUn9EUDfdffQ5ZK4CoJAAA=\"}!../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var chatHeader = 'ujru9z2';
export var chatHeaderTitle = 'ujru9z3';
export var container = 'ujru9z0';
export var dateLine = 'ujru9zb';
export var header = 'ujru9z1';
export var inputContainer = 'ujru9z4';
export var messageContainer = 'ujru9z5';
export var messageFromMe = 'ujru9z8';
export var messageFromMeContainer = 'ujru9z7';
export var messageToMe = 'ujru9za';
export var messageToMeContainer = 'ujru9z9';
export var textarea = 'ujru9z6';